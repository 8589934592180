import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../base.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss', './animate.min.css']
})
export class SurveyComponent extends BaseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  surveyId: string;
  jobId: string;
  candidateId: string;
  jobApplicationId: string;

  ngOnInit() {
    this.surveyId = this.getUrlParam(this.route, 'surveyId');
    this.jobId = this.getQueryParam(this.route, 'jobId')[0];
    this.candidateId = this.getQueryParam(this.route, 'candidateId')[0];
    this.jobApplicationId = this.getQueryParam(this.route, 'jobApplicationId')[0];
  }
}
