import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private http: HttpClient,
              private cookieService: CookieService,
              @Inject(PLATFORM_ID) private platformId: string) {
  }

  createContact(domain: string, contact: Contact): Observable<Contact> {
    return this.http.post<Contact>('./public/domain/' + domain + '/contact', contact);
  }

  createEvent(domain: string, contactId: string, interview: ContactInterview): Observable<ContactInterview> {
    return this.http.post<ContactInterview>('./public/domain/' + domain + '/contact/' + contactId + '/interview', interview);
  }

  updateEvent(domain: string, contactId: string, interviewId: string, passCode: string, interview: ContactInterview): Observable<ContactInterview> {
    return this.http.post<ContactInterview>('./public/domain/' + domain + '/contact/' + contactId + '/interview/' + interviewId
      + '?passCode=' + passCode, interview);  
    }

}

export interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  email: string;
}

export interface ContactInterview {
  id?: string
  startDate?: Date;
  endDate?: Date;
  interviewLength?: number;
  method?: string;
  phoneNumber?: string;
  visioConferenceURL?: string;
  interviewers: User[];
  status: string;
}

export interface User {
  id?: string;
  name?: string;
  email?: string;
  photo?: Photo;
  calendarSynchronizationConfiguration: CalendarSynchronizationConfiguration;
  timezone: string;
}

export interface CalendarSynchronizationConfiguration {
   sharedCalendar: SharedCalendar;
   enable: boolean;
   minInterviewLeadTime: number;
   maxInterviewOffset: number;
   interviewLength: number;
   timeBetweenMeeting: number;
   monday: Availability;
   tuesday: Availability;
   wednesday: Availability;
   thursday: Availability;
   friday: Availability;
   saturday: Availability;
   sunday: Availability;
}

export interface Availability {
  enable: boolean;
  startTimeOfDay: Date;
  endTimeOfDay: Date;
}

export interface SharedCalendar {
  enabled: boolean;
  lengths: number[];
  company: SharedCalendarProperty;
  reason: SharedCalendarProperty;
  description: SharedCalendarProperty;
}

export interface SharedCalendarProperty{
  enabled: boolean;
  required: boolean;
}

export interface Photo {
  url?: string;
}
