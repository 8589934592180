import { Field } from './field.model';
import { Screen } from './screen.model';

export class SurveyDefinition {
  id : string;
  title : string;
  description : string;
  welcomeScreens : Screen[];
  thankYouScreens : Screen[];
  screenerQuestions : Field[];
  demographicQuestions : Field[];
}
