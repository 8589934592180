import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { PipeFilterService } from '../../services/utils/pipeFilter.service';
import {ClientService} from '../../services/client/client.service';
import {ActivatedRoute} from '@angular/router';
import {BaseLimitedComponent} from '../base-limited.component';
import {JobTemplateService} from '../../services/job-template/job-template.service';
import {CandidateService} from '../../services/candidate/candidate.service';
import jwt_decode from 'jwt-decode';
import {JobService} from '../../services/job/job.service';
import {CustomizationService} from '../../services/customization/customization.service';

@Component({
  selector: 'app-candidate-account',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss']
})
export class CandidateComponent extends BaseLimitedComponent implements OnInit {

  constructor(
    private jobService: JobService,
    private candidateService: CandidateService,
    private jobTemplateService: JobTemplateService,
    private customizationService: CustomizationService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }
  private language: string;
  candidate;
  job;
  contractTypes = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.contractTypes : [];
  hierarchicalLevels = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.hierarchicalLevels : [];
  entities = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.entities : [];
  customization = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent : {};
  displayFiles;
  displayComments;
  newComment: string;
  candidateId: string;
  jobId: string;
  token: string;
  decoded: [];
  fileURL: string;

  ngOnInit() {
    this.language = this.translate.getDefaultLang();
    this.displayFiles = true;
    this.displayComments = false;

    this.candidateId = this.getUrlParam(this.route, 'candidateId');
    this.token = this.getQueryParam(this.route, 'token')[0];
    this.decoded = jwt_decode(this.token);
    this.fileURL = "";

    this.jobId = this.getJobId(this.decoded);
    this.jobService.getJobById(this.domain, this.jobId).subscribe(resource => {
      this.setJobDescription(resource);
      this.job = resource;
    });
    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.contractTypes = response.contractTypes;
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);

        this.hierarchicalLevels = response.hierarchicalLevels;
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);

        // set entities
        this.entities = response.entities;
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);
      }
    });

    this.candidateService.get(this.domain, this.candidateId, this.token).subscribe(resource => {
      this.candidate = resource;
      if (resource.comments) {
        for (const comment of resource.comments) {
          if (comment.jobId) {
            this.jobService.getJobById(this.domain, comment.jobId).subscribe(job => {
              this.setJobDescription(job);
              comment.job = job;
            });
          }
        }
      }
      if (resource.files) {
        for (const file of resource.files) {
          this.setDocumentUrl(file);
          break;
        }
      }
    });

    this.translate.onDefaultLangChange.subscribe((val) => {
      this.language = this.translate.getDefaultLang();
    });
  }

  setDocumentUrl(file) {
    for( const f of this.candidate.files) {
      f.selected = false;
    }
    file.selected = true;
    this.fileURL = this.baseUrl + '/public/domain/' + this.domain + '/candidate/' + this.candidateId + '/file/' + file.id + "/" + PipeFilterService.removeAccents(file.name) + '?token=' + this.token;
  }

  getJobId(decodedToken) {
    return decodedToken.jobId;
  }

  setJobDescription(job) {
    if (job !== undefined) {
      if (job.localizedJobs !== undefined && job.localizedJobs !== null) {
        for (const localizedJob of job.localizedJobs) {
          if (localizedJob.language === this.translate.getDefaultLang()) {
            job.title = localizedJob.title;
            job.shortDescription = localizedJob.shortDescription;
            job.fullDescription = localizedJob.fullDescription;
            job.shortDescription = localizedJob.shortDescription;
            break;
          }
        }
      }
    }
  }

  showFiles() {
    this.displayFiles = true;
    this.displayComments = false;
  }

  showComments() {
    this.displayFiles = false;
    this.displayComments = true;
  }

  saveComment() {
    const comment = {
      comment: this.newComment,
      jobId: this.jobId
    };
    this.candidateService.comment(this.domain, comment, this.candidateId, this.token).subscribe(resource => {
      this.candidate = resource;
    });
  }
}
