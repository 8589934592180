
import { Component, Inject, Input, OnInit } from '@angular/core';
import {BaseComponent} from '../base.component';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import { SurveyService } from '../../services/survey/survey.service';
import { SurveyDefinition } from '../../models/survey.model';
import { Answers } from '../../models/answers.model';
import { Field } from '../../models/field.model';
import { Answer } from '../../models/answer.model';
import { Option } from '../../models/option.model';

@Component({
  selector: 'app-survey-form',
  templateUrl: './surveyForm.component.html',
  styleUrls: ['./surveyForm.component.scss', './animate.min.css']
})
export class SurveyFormComponent extends BaseComponent implements OnInit {

  constructor(
    private surveyService: SurveyService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  @Input() surveyId: any;
  @Input() showBanner: any;
  @Input() jobId : any;
  @Input() candidateId : any;
  @Input() jobApplicationId : any;

  survey : SurveyDefinition;
  private answers : Answers;
  loading = true;
  private error : string;
  private surveySent = false;
  private validated = false;
  private surveyNotFound = false;
  private surveyAlreadyFilled = false;
  private unknownError =  false;
  style = BaseComponent.styleJobAlert ? BaseComponent.styleJobAlert : false;
  styleColorText = BaseComponent.styleColorJobAlert ? BaseComponent.styleColorJobAlert : false;
  private choiceSettings = {};
  private choicesSettings = {};

  private choices = []
  private text?: string;
  private choiceId?: Option[];
  private other?:	string;
  private choiceIds?:	Option[];
  private value?: boolean;
  private number?: number;
  private date?: string;
  private url?: string;
  private score?: number;
  private buttonName: string = "NEXT";

  private originalScore : number = 0;
  elementId : string;

  ngOnInit() {

    BaseComponent.profile.subscribe((response: any) => {
      if (response) {
        if (response.backgroundJobAlertId) {
          this.styleColorText = {
            color : response.jobAlertTextColor
          };
          this.style = {
            color : response.jobAlertTextColor,
            background: 'url(' + this.baseUrl + '/files/' + response.backgroundJobAlertId + ')',
            'background-repeat': 'no-repeat',
            'background-position-x': 'center'
          };
        }
      }
    });
    this.doLoadSurvey();

    this.translate.get(['SEARCH', 'SELECT', 'SELECT_ALL',
      'UNSELECT_ALL']).subscribe(translations => {
      this.choicesSettings =  {
        enableCheckAll: false,
        singleSelection: false,
        idField: 'id',
        textField: 'label',
        searchPlaceholderText: translations['SEARCH'],
        selectAllText: translations['SELECT_ALL'],
        unSelectAllText: translations['UNSELECT_ALL'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
      this.choiceSettings =  {
        enableCheckAll: false,
        singleSelection: true,
        closeDropDownOnSelection: true,
        idField: 'id',
        textField: 'label',
        searchPlaceholderText: translations['SEARCH'],
        selectAllText: translations['SELECT_ALL'],
        unSelectAllText: translations['UNSELECT_ALL'],
        itemsShowLimit: 3,
        allowSearchFilter: true
      };
    });
  }

  //function to return list of numbers from 0 to n-1
  numSequence(n: number): Array<number> {
    return Array(n);
  }

  mouseEnter(step : number){
    this.originalScore = this.score;
    this.score = step;
  }

  setStep(step : number){
    this.score = step;
    this.originalScore = step;
  }

  reset(){
    this.score = 0;
    this.originalScore = 0;
  }

  mouseLeave(){
    this.score = this.originalScore;
  }

  nextWelcomeScreen(id: string) {
    let nextIndex = this.survey.welcomeScreens.findIndex((element) => element.id == id) + 1;
    let nextElementId : string;
    if(nextIndex < this.survey.welcomeScreens.length) {
      nextElementId = this.survey.welcomeScreens[nextIndex].id
    } else {
      nextElementId = this.survey.screenerQuestions[0].id
    }

    this.doNext(id, nextElementId);
  };

  previousWelcomeScreen(id: string) {
    let previousIndex = this.survey.welcomeScreens.findIndex((element) => element.id == id) - 1;
    let previousElementId = this.survey.welcomeScreens[previousIndex].id

    this.doPrevious(id, previousElementId);
  };

  validateText(answer : Answer, field : Field) : string {
    if(field.validation != null) {
        if(field.validation.required && answer.text == undefined){
          return "exception.required";
        }
        if(answer.text != undefined) {
            if (field.validation.maxLength != null) {
                if (answer.text.length > field.validation.maxLength) {
                    return "exception.too_long";
                }
            }
            if (field.validation.minLength != null) {
                if (answer.text.length < field.validation.minLength) {
                    return "exception.too_short";
                }
            }
        }
    }
  }

  validateNumber(answer : Answer, field : Field) : string {
      if(field.validation != null) {
          if(field.validation.required && answer.number == null){
            return "exception.required";
          }
          if(answer.number != null) {
              if (field.validation.maxValue != null) {
                  if (answer.number > field.validation.maxValue) {
                    return "exception.too_high";
                  }
              }
              if (field.validation.minValue != null) {
                  if (answer.number < field.validation.minValue) {
                    return "exception.too_low";
                  }
              }
          }
      }
  }

  validateRating(answer : Answer, field : Field) : string {
      if(field.validation != null) {
          if(field.validation.required && answer.score == null){
            return "exception.required";
          }
      }
  }

  validateWebsite(answer : Answer, field : Field) : string {
      if(field.validation != null) {
          if(field.validation.required && answer.url == null){
            return "exception.required";
          }
          if(answer.url != null && !this.isValidUrl(answer.url)) {
            return "exception.url_not_valid";
          }
      }
  }

  isValidUrl(string) : boolean {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }

  validateBoolean(answer : Answer, field : Field) : string {
      if(field.validation != null) {
          if(field.validation.required && answer.value == null){
            return "exception.required";
          }
      }
  }

  validateDate(answer : Answer, field : Field) : string {
      if(field.validation != null) {
          if(field.validation.required && answer.date == null){
            return "exception.required";
          }
      }
  }

  validateDropdown(answer : Answer, field : Field) : string {
    if(field.validation != null) {
      if(field.validation.required){
        if(answer.choice == null ||
                (answer.choice.other == null && answer.choice.id == null)) {
          return "exception.required";
        }
      }
    }
  }

  validateMultiChoice(answer : Answer, field : Field) : string {
    if(field.validation != null) {
      if(field.validation.required){
        if(answer.choices == null || answer.choices.ids == undefined || answer.choices.ids.length == 0) {
          return "exception.required";
        }
      }
      if(answer.choices != null) {
        if((answer.choices.ids != undefined && answer.choices.ids.length != 0) && field.validation != null && field.validation.minSelection != null
            && answer.choices.ids.length < field.validation.minSelection) {
          return "exception.not_enough_selection";
        } else if((answer.choices.ids != undefined && answer.choices.ids.length != 0) && field.validation != null && field.validation.maxSelection != null
                && answer.choices.ids.length > field.validation.maxSelection) {
          return "exception.not_much_selection";
        }
      }
    }
  }

  nextScreenerQuestion(id: string) {
    let nextIndex = this.survey.screenerQuestions.findIndex((element) => element.id == id) + 1;
    let nextElementId : string;
    if(nextIndex < this.survey.screenerQuestions.length) {
      nextElementId = this.survey.screenerQuestions[nextIndex].id
      if(nextIndex + 1 == this.survey.screenerQuestions.length) {
        this.buttonName = "SUBMIT"
      }
    } else if(this.survey.demographicQuestions != null && this.survey.demographicQuestions.length > 0){
      nextElementId = this.survey.demographicQuestions[0].id;
      if(nextIndex + 1 == this.survey.demographicQuestions.length) {
        this.buttonName = "SUBMIT"
      }
    } else if(this.survey.thankYouScreens != null && this.survey.thankYouScreens.length > 0){
      nextElementId = this.survey.thankYouScreens[0].id
      if(this.survey.thankYouScreens.length == 1) {
        this.buttonName = "SUBMIT"
      }
    }
    let field : Field = this.survey.screenerQuestions.find((element) => element.id == id);
    let answer : Answer = this.answers.answers.find((element) => element.fieldId == id);
    this.error = undefined;
    if(field.type == 'TEXT' ||field.type == 'TEXT_LONG' || field.type == 'EMAIL' || field.type == 'PHONE_NUMBER') {
      answer.text = this.text;
      this.error = this.validateText(answer, field);
    } else if(field.type == 'YES_NO') {
      answer.value = this.value;
      this.error = this.validateBoolean(answer, field);
    } else if(field.type == 'DATE') {
      answer.date = this.date;
      this.error = this.validateDate(answer, field);
    } else if(field.type == 'DROPDOWN') {
      answer.choice =  { id : this.choiceId ? this.choiceId[0].id : undefined, other: this.other};
      this.error = this.validateDropdown(answer, field);
    } else if(field.type == 'MULTIPLE_CHOICE') {
      answer.choices =  { ids : this.choiceIds ? this.choiceIds.map(option => option.id) : undefined};
      this.error = this.validateMultiChoice(answer, field);
    } else if(field.type == 'NUMBER') {
      answer.number = this.number;
      this.error = this.validateNumber(answer, field);
    } else if(field.type == 'RATING') {
      answer.score = this.score;
      this.error = this.validateRating(answer, field);
    } else if(field.type == 'WEBSITE') {
      answer.url = this.url;
      this.error = this.validateWebsite(answer, field);
    }
    if(this.error == undefined) {
      if(nextIndex == this.survey.screenerQuestions.length && (this.survey.thankYouScreens == null || this.survey.thankYouScreens.length == 0)){
        this.submit();
      } else {
        this.doNext(id, nextElementId);
      }
    }
  };

  nextDemographicQuestion(id: string) {
    let nextIndex = this.survey.demographicQuestions.findIndex((element) => element.id == id) + 1;
    let nextElementId : string;
    if(nextIndex < this.survey.demographicQuestions.length) {
      nextElementId = this.survey.demographicQuestions[nextIndex].id
      if(nextIndex + 1 == this.survey.demographicQuestions.length) {
        this.buttonName = "SUBMIT"
      }
    } else if(this.survey.thankYouScreens != null && this.survey.thankYouScreens.length > 0){
      nextElementId = this.survey.thankYouScreens[0].id
      if(this.survey.thankYouScreens.length == 1) {
        this.buttonName = "SUBMIT"
      }
    }
    let field : Field = this.survey.demographicQuestions.find((element) => element.id == id);
    let answer : Answer = this.answers.answers.find((element) => element.fieldId == id);
    this.error = undefined;
    if(field.type == 'TEXT' ||field.type == 'TEXT_LONG' || field.type == 'EMAIL' || field.type == 'PHONE_NUMBER') {
      answer.text = this.text;
      this.error = this.validateText(answer, field);
    } else if(field.type == 'YES_NO') {
      answer.value = this.value;
      this.error = this.validateBoolean(answer, field);
    } else if(field.type == 'DATE') {
      answer.date = this.date;
      this.error = this.validateDate(answer, field);
    } else if(field.type == 'DROPDOWN') {
      answer.choice =  { id : this.choiceId ? this.choiceId[0].id : undefined, other: this.other};
      this.error = this.validateDropdown(answer, field);
    } else if(field.type == 'MULTIPLE_CHOICE') {
      answer.choices =  { ids : this.choiceIds ? this.choiceIds.map(option => option.id) : undefined};
      this.error = this.validateMultiChoice(answer, field);
    } else if(field.type == 'NUMBER') {
      answer.number = this.number;
      this.error = this.validateNumber(answer, field);
    } else if(field.type == 'RATING') {
      answer.score = this.score;
      this.error = this.validateRating(answer, field);
    } else if(field.type == 'WEBSITE') {
      answer.url = this.url;
      this.error = this.validateWebsite(answer, field);
    }
    if(this.error == undefined) {
      if(nextIndex == this.survey.screenerQuestions.length && (this.survey.thankYouScreens == null || this.survey.thankYouScreens.length == 0)){
        this.submit();
      } else {
        this.doNext(id, nextElementId);
      }
    }
  };

  previousScreenerQuestion(id: string) {
    let previousIndex = this.survey.screenerQuestions.findIndex((element) => element.id == id) - 1;
    let previousElementId : string;
    this.buttonName = "NEXT"
    if(previousIndex > -1) {
      previousElementId = this.survey.screenerQuestions[previousIndex].id
    } else {
      previousElementId = this.survey.welcomeScreens[this.survey.welcomeScreens.length-1].id
    }

    this.doPrevious(id, previousElementId);
  };

  previousDemographicQuestion(id: string) {
    let previousIndex = this.survey.demographicQuestions.findIndex((element) => element.id == id) - 1;
    let previousElementId : string;
    this.buttonName = "NEXT"
    if(previousIndex > -1) {
      previousElementId = this.survey.demographicQuestions[previousIndex].id
    } else if(this.survey.screenerQuestions && this.survey.screenerQuestions.length > 0){
      previousElementId = this.survey.screenerQuestions[this.survey.screenerQuestions.length-1].id
    } else {
      previousElementId = this.survey.welcomeScreens[this.survey.welcomeScreens.length-1].id
    }

    this.doPrevious(id, previousElementId);
  };

  nextThankYouScreen(id: string) {
    let nextIndex = this.survey.thankYouScreens.findIndex((element) => element.id == id) + 1;
    if(nextIndex == this.survey.thankYouScreens.length ){
      this.submit();
    } else{
      let nextElementId = this.survey.thankYouScreens[nextIndex].id
      if(this.survey.thankYouScreens.length == nextIndex) {
        this.buttonName = "SUBMIT"
      }
      this.doNext(id, nextElementId);
    }
  };

  previousThankYouScreen(id: string) {
    let previousIndex = this.survey.thankYouScreens.findIndex((element) => element.id == id) - 1;
    let previousElementId : string;
    this.buttonName = "NEXT"
    if(previousIndex > -1) {
      previousElementId = this.survey.thankYouScreens[previousIndex].id
    } else if(this.survey.demographicQuestions && this.survey.demographicQuestions.length > 0){
      previousElementId = this.survey.demographicQuestions[this.survey.demographicQuestions.length-1].id
    } else {
      previousElementId = this.survey.screenerQuestions[this.survey.screenerQuestions.length-1].id
    }

    this.doPrevious(id, previousElementId);
  };

  doNext(currentElementId : string, nextElementId: string) {
    const currentAnimationBox = document.getElementById(currentElementId);
    currentAnimationBox.classList.remove("hidden")
    currentAnimationBox.classList.add("animate__backOutLeft" , "animate__animated" , "animate__repeat-1" , "animate__slow");
    setTimeout(() => {
      let field : Field = this.survey.screenerQuestions.find((element) => element.id == nextElementId) || 
      this.survey.demographicQuestions.find((element) => element.id == nextElementId);
      let answer : Answer = this.answers.answers.find((element) => element.fieldId == nextElementId);

      if(answer) {
        if(field.type == 'MULTIPLE_CHOICE') {
          this.choices = field.property.choices;
        } else if (field.type == "DROPDOWN") {
          this.choices = field.property.choices;
          if(field.property.allowOtherChoice) {
            this.translate.get(['Other']).subscribe(translations => {
              this.choices.push({id : null,label : translations['Other']});
            });
          }
        }

        this.text = undefined;
        this.choiceId = [];
        this.choiceIds = [];
        this.value = undefined;
        this.number = undefined;
        this.date = undefined;
        this.url = undefined;
        this.score = undefined;

        if(field.type == 'DATE') {
          this.date = answer.date;
          setTimeout(() => {                           // <<<---using ()=> syntax
            const button = document.getElementById('datetimepickerbutton') as HTMLInputElement | null;
            if (button) {
              button.addEventListener('click', async () => {
                try {

                  // @ts-ignore
                  const input = document.getElementById('datetimepicker') as HTMLInputElement | null;
                  input.type = 'date';
                  // @ts-ignore
                  await input.showPicker();
                  // A date picker is shown.
                } catch (error) {
                  // Use external library when this fails.
                  console.log(error);
                }
              });
            }
          }, 2000);
        } else if(field.type == 'DROPDOWN') {
          this.choiceId = answer.choice ? field.property.choices.filter((element) => element.id == answer.choice.id) : undefined;
          this.other = answer.choice ? answer.choice.other : undefined;
        } else if(field.type == 'MULTIPLE_CHOICE') {
          this.choiceIds = answer.choices && answer.choices.ids ? field.property.choices.filter((element) => answer.choices.ids.indexOf(element.id) > -1) : undefined;
        } else if(field.type == 'NUMBER') {
          this.number = answer.number;
        } else if(field.type == 'RATING') {
          this.score = answer.score;
        } else if(field.type == 'TEXT' || field.type == 'TEXT_LONG' || field.type == 'EMAIL' || field.type == 'PHONE_NUMBER') {
          this.text = answer.text;
        } else if(field.type == 'WEBSITE') {
          this.url = answer.url;
        } else if(field.type == 'YES_NO') {
          this.value = answer.value;
        }
      }

      currentAnimationBox.classList.add("hidden")
      currentAnimationBox.classList.remove("animate__backOutLeft" , "animate__animated" , "animate__repeat-1" , "animate__slow")
      const nextAnimationBox = document.getElementById(nextElementId);
      nextAnimationBox.classList.remove("hidden")
      nextAnimationBox.classList.add("animate__backInRight" , "animate__animated" , "animate__repeat-1" , "animate__slow");
      setTimeout(() => {
        nextAnimationBox.classList.remove("animate__backInRight" , "animate__animated" , "animate__repeat-1" , "animate__slow")
      }, 2000);
    }, 1000);
  }

  doPrevious(currentElementId : string, previousElementId: string) {
    const currentAnimationBox = document.getElementById(currentElementId);
    currentAnimationBox.classList.remove("hidden")
    currentAnimationBox.classList.add("animate__backOutRight" , "animate__animated" , "animate__repeat-1" , "animate__slow");

    setTimeout(() => {
      let field : Field = this.survey.screenerQuestions.find((element) => element.id == previousElementId) || 
          this.survey.demographicQuestions.find((element) => element.id == previousElementId);
      let answer : Answer = this.answers.answers.find((element) => element.fieldId == previousElementId);

      if(answer){
        if(field.type == 'MULTIPLE_CHOICE' || field.type == "DROPDOWN") {
          this.choices = field.property.choices;
        }

        this.text = undefined;
        this.choiceId = [];
        this.choiceIds = [];
        this.value = undefined;
        this.number = undefined;
        this.date = undefined;
        this.url = undefined;
        this.score = undefined;

        if(field.type == 'DATE') {
          this.date = answer.date;
        } else if(field.type == 'DROPDOWN') {
          this.choiceId = answer.choice ? field.property.choices.filter((element) => element.id == answer.choice.id) : undefined;
          this.other = answer.choice ? answer.choice.other : undefined;
        } else if(field.type == 'MULTIPLE_CHOICE') {
          this.choiceIds = answer.choices && answer.choices.ids ? field.property.choices.filter((element) => answer.choices.ids.indexOf(element.id) > -1) : undefined;
        } else if(field.type == 'NUMBER') {
          this.number = answer.number;
        } else if(field.type == 'RATING') {
          this.score = answer.score;
        } else if(field.type == 'TEXT' || field.type == 'TEXT_LONG' || field.type == 'EMAIL' || field.type == 'PHONE_NUMBER') {
          this.text = answer.text;
        } else if(field.type == 'WEBSITE') {
          this.url = answer.url;
        } else if(field.type == 'YES_NO') {
          this.value = answer.value;
        }
      }

      currentAnimationBox.classList.add("hidden")
      currentAnimationBox.classList.remove("animate__backOutRight" , "animate__animated" , "animate__repeat-1" , "animate__slow")
      const previousAnimationBox = document.getElementById(previousElementId);
      previousAnimationBox.classList.remove("hidden")
      previousAnimationBox.classList.add("animate__backInLeft" , "animate__animated" , "animate__repeat-1" , "animate__slow");
      setTimeout(() => {
        previousAnimationBox.classList.remove("animate__backInLeft" , "animate__animated" , "animate__repeat-1" , "animate__slow")
      }, 2000);
    }, 1000);
  }

  private submit(){
    this.loading = true;
    this.surveyService.submit(this.domain, this.surveyId, this.answers).subscribe((resource) => {
      this.surveySent = true;
      this.loading = false;
    }, (error) => {
      console.log(error);
      this.loading = false;
    });

  }

  private doLoadSurvey() {
    this.surveyAlreadyFilled = false;
    this.surveyNotFound = false;
    this.unknownError = false;
    this.loading = true;
    this.survey = new SurveyDefinition();
    this.surveyService.get(this.domain, this.surveyId).subscribe((resource) => {
      this.survey = resource
      this.loading = false;

      this.answers = {
        surveyDefinitionId: this.surveyId,
        jobId : this.jobId,
        candidateId : this.candidateId,
        jobApplicationId : this.jobApplicationId,
        answers : []
      };
      for(let i = 0; i < this.survey.screenerQuestions.length; i++) {
        this.answers.answers.push({fieldId : this.survey.screenerQuestions[i].id})
      }
      for(let i = 0; i < this.survey.screenerQuestions.length; i++) {
        this.answers.answers.push({fieldId : this.survey.demographicQuestions[i].id})
      }

      setTimeout(() => {
        let id : string;
        if(this.survey.welcomeScreens && this.survey.welcomeScreens.length >0) {
          id = this.survey.welcomeScreens[0].id;
        } else {
          id = this.survey.screenerQuestions[0].id;
        }

        const animationBox = document.getElementById(id);
        animationBox.classList.remove("hidden")
        animationBox.classList.add("animate__fadeIn" , "animate__animated" , "animate__repeat-1" , "animate__slow");
        setTimeout(() => {
          animationBox.classList.remove("animate__fadeIn" , "animate__animated" , "animate__repeat-1" , "animate__slow")
        }, 2000);
      }, 0);
    }, (response) => {
      if(response.error.status == 410){
        this.surveyAlreadyFilled = true;
      } else if(response.error.status == 404){
        this.surveyNotFound = true;
      } else {
        this.unknownError = true;
      }
      this.loading = false;
    });
  }
}
