import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {CandidateService, Interview} from '../../services/candidate/candidate.service';
import {Contact} from '../../services/contact/contact.service';
import {BaseLimitedComponent} from '../base-limited.component';
import {CustomizationService} from '../../services/customization/customization.service';
import {UserService} from '../../services/user/user.service';
import {CalendarEvent} from '../../services/user/user.service';
import * as moment from 'moment-timezone';
import { ContactInterview, ContactService, User } from '../../services/contact/contact.service';

@Component({
  selector: 'app-contact-interview',
  templateUrl: './contact-interview.component.html',
  styleUrls: ['./contact-interview.component.scss']
})
export class ContactInterviewComponent extends BaseLimitedComponent implements OnInit {

  constructor(
    private contactService: ContactService,
    private userService: UserService,
    private candidateService: CandidateService,
    private customizationService: CustomizationService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  private language: string;
  loadingInterviewSlot = false;
  errorInterviewSlot = false;
  slotNotAvailable = false;
  updating = false;
  interview;
  candidate;
  job;
  justSaved: boolean;
  interviewer;
  now;
  days: Day[];
  interviews  = [];
  contractTypes = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.contractTypes : [];
  hierarchicalLevels = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.hierarchicalLevels : [];
  entities = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.entities : [];
  customization = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent : {};
  profile = BaseLimitedComponent.profileContent ? BaseLimitedComponent.profileContent : {};
  candidateId: string;
  jobInterviewId: string;
  contactInterview: ContactInterview;
  passCode: string;
  jobId: string;
  interviewerId: string;
  interviewType: string;
  thisMonth: boolean;
  firstDayCurrentMonth: Date;
  lastDayCurrentMonth: Date;
  lastDayPreviousMonth: Date;
  firstDayNextMonth: Date;
  lastDayNextMonth: Date;
  daysAvailableNextMonth: number;
  ngOnInit() {

    BaseLimitedComponent.initializeCustomization(this.customizationService, this.route, this.domain);
    this.days  = new Array();
    this.now = new Date();
    this.justSaved = false;
    this.firstDayCurrentMonth =  new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.lastDayCurrentMonth =  new Date(this.now.getFullYear(), this.now.getMonth() + 1 , 0);
    this.lastDayPreviousMonth =  new Date(this.now.getFullYear(), this.now.getMonth(), 0);

    this.firstDayNextMonth =  new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);
    this.lastDayNextMonth =  new Date(this.now.getFullYear(), this.now.getMonth() + 2 , 0);

    this.language = this.translate.getDefaultLang();
    this.jobInterviewId = this.getUrlParam(this.route, 'jobInterviewId');
    this.candidateId = this.getQueryParam(this.route, 'candidateId');
    this.passCode = this.getQueryParam(this.route, 'passCode');
    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.profile = response;
      }
    });

    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.contractTypes = response.contractTypes;
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);

        this.hierarchicalLevels = response.hierarchicalLevels;
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);

        // set entities
        this.entities = response.entities;
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);
      }
    });

    this.translate.onDefaultLangChange.subscribe((val) => {
      this.language = this.translate.getDefaultLang();
    });
  }

  buildThisMonth() {
    this.thisMonth = true;
    let dayCount = 1;
    for (let i = 1; i <= this.lastDayPreviousMonth.getDay(); i++) {
      this.days[dayCount] = {
        selected: false, disabled: true,
        number: this.lastDayPreviousMonth.getDate() - this.lastDayPreviousMonth.getDay() + i,
        month: this.lastDayPreviousMonth.getMonth(), year: this.lastDayPreviousMonth.getFullYear()
      };

      dayCount++;
    }
    let numberOfDaysInAdvance = this.interviewer.calendarSynchronizationConfiguration.maxInterviewOffset;
    for (let i = 1; i <= this.lastDayCurrentMonth.getDate(); i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayCurrentMonth.getFullYear(), this.lastDayCurrentMonth.getMonth(), i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && numberOfDaysInAdvance > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            numberOfDaysInAdvance--;
            isInRange = true;
          }
        }
      }
      this.daysAvailableNextMonth = numberOfDaysInAdvance;
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayCurrentMonth.getMonth(), year: this.firstDayCurrentMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 1; dayCount <= 35; i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayCurrentMonth.getFullYear(), this.lastDayCurrentMonth.getMonth() + 1, i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && numberOfDaysInAdvance > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            numberOfDaysInAdvance--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayCurrentMonth.getMonth() + 1, year: this.firstDayCurrentMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 0; i <= 35; i++) {
      if (this.selectedDay(this.days.find(element => {
        return element && !element.disabled && element.number === this.now.getDate() + i;
      }))) {
        return;
      }
    }
  }

  buildNextMonth() {
    this.thisMonth = false;

    let dayCount = 1;
    let numberOfDaysInAdvance = this.interviewer.calendarSynchronizationConfiguration.maxInterviewOffset;
    for (let i = 1; i <= this.lastDayCurrentMonth.getDay(); i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayCurrentMonth.getFullYear(), this.lastDayCurrentMonth.getMonth(), this.lastDayCurrentMonth.getDate() - this.lastDayCurrentMonth.getDay() + i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && numberOfDaysInAdvance > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            numberOfDaysInAdvance--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: this.lastDayCurrentMonth.getDate() - this.lastDayCurrentMonth.getDay() + i,
        day,
        month: this.lastDayCurrentMonth.getMonth(), year: this.lastDayCurrentMonth.getFullYear(),
        date
      };
      dayCount++;
    }
    // for (let i = 1; i <= this.lastDayCurrentMonth.getDay(); i++) {
    //   this.days[dayCount] = {
    //     selected: false, disabled: true,
    //     number: this.lastDayCurrentMonth.getDate() - this.lastDayCurrentMonth.getDay() + i,
    //     month: this.lastDayCurrentMonth.getMonth(), year: this.lastDayCurrentMonth.getFullYear()
    //   };
    //
    //   dayCount++;
    // }
    for (let i = 1; i <= this.lastDayNextMonth.getDate(); i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayNextMonth.getFullYear(), this.lastDayNextMonth.getMonth(), i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && this.daysAvailableNextMonth > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayNextMonth.getMonth(), year: this.firstDayNextMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 1; dayCount <= 35; i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayNextMonth.getFullYear(), this.lastDayNextMonth.getMonth() + 1, i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && this.daysAvailableNextMonth > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayNextMonth.getMonth() + 1, year: this.firstDayNextMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 0; i <= 35; i++) {
      if (this.selectedDay(this.days.find(element => {
        return element && !element.disabled && element.number === this.now.getDate() + i;
      }))) {
        return;
      }
    }
  }
  changeTime() {
    this.contactInterview.status  = 'SENT';
  }

  cancel() {
    this.updating = true;
    const event = {
      startDate: this.contactInterview.startDate,
      endDate: this.contactInterview.endDate,
      candidateId: this.candidateId,
      jobId: this.jobId,
      status: 'CANCEL'
    };
    this.candidateService.createEvent(this.domain, this.candidateId, this.jobInterviewId, this.passCode, event).subscribe(
      (result: Interview) => {
        this.updating = false;
        this.contactInterview.status = 'CANCEL';
        this.interview = result;
      },
      (error) => {
        console.error(error);
        this.updating = false;
      });
  }

  valideDate(): boolean {
    return new Date(this.contactInterview.startDate) > this.now;
  }

  confirm(time: Date) {
    this.updating = true;
    this.slotNotAvailable = false;
    let day = this.days.find(element => {
      return element && element.selected;
    })

    this.userService.getCalendar(this.domain, this.interviewerId, day.date).subscribe(
      (userCalendarEvents: CalendarEvent[]) => {
      this.convertToDate(day, userCalendarEvents);
      if (this.availableDateTime(time, userCalendarEvents)) {
        let event = {
          startDate: time,
          endDate: new Date(time.getTime() + this.contactInterview.interviewLength * 60000),
          candidateId: this.candidateId,
          jobId: this.jobId,
          status: 'ACCEPTED',
          offset : time.getTimezoneOffset(),
        };
        this.candidateService.createEvent(this.domain, this.candidateId, this.jobInterviewId, this.passCode, event).subscribe(
          (result: Interview) => {
            this.justSaved = true;
            this.updating = false;
            this.contactInterview.status  = result.status;
            this.contactInterview.startDate  = result.startDate;
            this.contactInterview.endDate  = result.endDate;
            this.contactInterview.visioConferenceURL  = result.visioConferenceURL;
            this.interview = result;
          },
          (error) => {
            console.error(error);
            this.updating = false;
            this.errorInterviewSlot = true;
          });
      } else {
        this.selectedDay(day)
        this.updating = false;
        this.slotNotAvailable = true;
      }
    },
      (error) => {
        console.error(error);
        this.selectedDay(day)
        this.updating = false;
        this.slotNotAvailable = true;
      }
    );

  }

  selectedDay(day: Day) {
    this.days.map(element => element.selected = false);
    this.interviews = undefined;
    this.interview = undefined;
    if (day && !day.disabled) {
      this.loadingInterviewSlot = true;
      this.days.map(element => element.selected = false);
      day.selected = true;
      this.interview = {date : day.date};
      this.interviews = undefined;
      this.errorInterviewSlot = false;
      this.userService.getCalendar(this.domain, this.interviewerId, day.date).subscribe(
        (userCalendarEvents: CalendarEvent[]) => {
        this.convertToDate(day, userCalendarEvents);
        this.loadingInterviewSlot = false;

        this.interviews = [];
        const dayConfig = this.interviewer.calendarSynchronizationConfiguration[day.day];
        const startHours  = dayConfig.startTimeOfDay.split(':')[0];
        const startMinutes  = dayConfig.startTimeOfDay.split(':')[1];
        const startMoment  = moment.tz(new Date(day.year, day.month,  day.number, startHours, startMinutes), this.interviewer.timezone);
        startMoment.hours(startHours);
        let startDate = startMoment.toDate();
        if (startDate <  this.now) {
          startDate =  new Date(this.now.getTime() + this.interviewer.calendarSynchronizationConfiguration.minInterviewLeadTime * 60000);
          if (startDate.getMinutes() > 0 && startDate.getMinutes() <= 14) {
            startDate.setMinutes(15);
          } else if (startDate.getMinutes() > 15 && startDate.getMinutes() <= 29) {
            startDate.setMinutes(30);
          } else if (startDate.getMinutes() > 30 && startDate.getMinutes() <= 44) {
            startDate.setMinutes(45);
          } else {
            startDate.setHours(startDate.getHours() + 1);
            startDate.setMinutes(0);
          }
        }
        const endHours = dayConfig.endTimeOfDay.split(':')[0];
        const endMinutes  = dayConfig.endTimeOfDay.split(':')[1];
        const endMoment  = moment.tz(new Date(day.year, day.month,  day.number, endHours, endMinutes), this.interviewer.timezone);
        endMoment.hours(endHours);
        const endDate = endMoment.toDate();
        if (endDate < startDate) {
          return;
        }
        do {
          if (this.availableDateTime(startDate, userCalendarEvents)) {
            this.interviews.push({date: startDate, selected: false});
          }
          startDate =  new Date(startDate.getTime() + 15 * 60000);
        } while (startDate.getTime() + this.contactInterview.interviewLength * 60000 <= endDate.getTime());

      },
        (error) => {
          console.error(error);
          this.errorInterviewSlot = true;
          this.loadingInterviewSlot = false;
        }
      );
      return true;
    } else {
      return false;
    }
  }

  private availableDateTime(date: Date, events: CalendarEvent[]) {
    for (const event of events) {
      if (date >= event.startDateTime && date  < event.endDateTime) {
        return false;
      }
      const startDate = new Date(date.getTime() + this.contactInterview.interviewLength * 60000);
      if (startDate > event.startDateTime && startDate < event.endDateTime) {
        return false;
      }
    }
    return true;
  }


  private convertToDate(day: Day, calendarEvents: CalendarEvent[]) {
    for (const event of calendarEvents) {
      let startYear;
      let startMonth;
      let startDay;
      if(event.startDate !== "") {
        startYear  = Number(event.startDate.split('-')[0]);
        startMonth  = Number(event.startDate.split('-')[1]) - 1;
        startDay  = Number(event.startDate.split('-')[2]);
      } else {
        startYear  = day.year;
        startMonth  = day.month;
        startDay  = day.number;
      }
      const startHours  = Number(event.startTime.split(':')[0]);
      const startMinutes  =  Number(event.startTime.split(':')[1]);
      const timeZone = event.timeZone;
      const startMoment  = moment.tz(new Date(startYear, startMonth,  startDay, startHours, startMinutes), timeZone);
      startMoment.hours(Number(startHours));
      event.startDateTime = startMoment.toDate();
      const endHours  = Number(event.endTime.split(':')[0]);
      const endMinutes  =  Number(event.endTime.split(':')[1]);
      let endYear;
      let endMonth;
      let endDay;
      if(event.endDate !== "") {
        endYear  = Number(event.endDate.split('-')[0]);
        endMonth  = Number(event.endDate.split('-')[1]) -1;
        endDay  = Number(event.endDate.split('-')[2]);
      } else {
        endYear  = day.year;
        endMonth  = day.month;
        endDay  = day.number;
      }
      const endMoment  = moment.tz(new Date(endYear, endMonth,  endDay, endHours, endMinutes), timeZone);
      endMoment.hours(endHours);
      event.endDateTime = endMoment.toDate();
    }
  }

  selectedTime(interview) {
    this.interviews.map(element => element.selected = false);
    interview.selected = true;
  }

  setJobDescription(job) {
    if (job !== undefined) {
      if (job.localizedJobs !== undefined && job.localizedJobs !== null) {
        for (const localizedJob of job.localizedJobs) {
          if (localizedJob.language === this.translate.getDefaultLang()) {
            job.title = localizedJob.title;
            job.shortDescription = localizedJob.shortDescription;
            job.fullDescription = localizedJob.fullDescription;
            job.shortDescription = localizedJob.shortDescription;
            break;
          }
        }
      }
    }
  }


}

@Component({
  selector: 'app-new-contact-interview',
  templateUrl: './new-contact-interview.component.html',
  styleUrls: ['./contact-interview.component.scss']
})
export class NewContactInterviewComponent extends BaseLimitedComponent implements OnInit {

  constructor(
    private contactService: ContactService,
    private userService: UserService,
    private candidateService: CandidateService,
    private customizationService: CustomizationService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    @Inject('BASE_API_URL') private baseUrl: string) {
    super();
    this.initializeProperties(window, route, this.translate);
  }

  private language: string;
  loadingInterviewSlot = false;
  errorInterviewSlot = false;
  error : ContactError = {};
  slotNotAvailable = false;
  updating = false;
  interview;
  contact: ContactView;
  justSaved: boolean;
  interviewer : User;
  now : Date;
  days: Day[];
  selectedSlot = false;
  interviews  = [];
  contractTypes = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.contractTypes : [];
  hierarchicalLevels = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.hierarchicalLevels : [];
  entities = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent.entities : [];
  customization = BaseLimitedComponent.customizationContent ? BaseLimitedComponent.customizationContent : {};
  profile = BaseLimitedComponent.profileContent ? BaseLimitedComponent.profileContent : {};
  candidateId: string;
  contactInterviewId: string;
  contactInterview: ContactInterview;
  passCode: string;
  jobId: string;
  interviewLength: number;
  interviewerId: string;
  interviewType: string;
  thisMonth: boolean;
  firstDayCurrentMonth: Date;
  lastDayCurrentMonth: Date;
  lastDayPreviousMonth: Date;
  firstDayNextMonth: Date;
  lastDayNextMonth: Date;
  daysAvailableNextMonth: number;
  ngOnInit() {

    BaseLimitedComponent.initializeCustomization(this.customizationService, this.route, this.domain);
    this.days  = new Array();
    this.now = new Date();
    this.justSaved = false;
    this.contact = {};
    this.firstDayCurrentMonth =  new Date(this.now.getFullYear(), this.now.getMonth(), 1);
    this.lastDayCurrentMonth =  new Date(this.now.getFullYear(), this.now.getMonth() + 1 , 0);
    this.lastDayPreviousMonth =  new Date(this.now.getFullYear(), this.now.getMonth(), 0);

    this.firstDayNextMonth =  new Date(this.now.getFullYear(), this.now.getMonth() + 1, 1);
    this.lastDayNextMonth =  new Date(this.now.getFullYear(), this.now.getMonth() + 2 , 0);

    this.language = this.translate.getDefaultLang();
    this.interviewerId = this.getUrlParam(this.route, 'userId');
    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.profile = response;
      }
    });


    this.userService.getUserById(this.domain, this.interviewerId).subscribe((resource : User) => {
      this.interviewer = resource;
      this.contactInterview = {interviewers : [this.interviewer], status: "PENDING", method: "PHONE_INTERVIEW"}
      this.interviewLength = this.interviewer.calendarSynchronizationConfiguration.sharedCalendar.lengths[0];
      this.buildThisMonth();
    });

    this.customizationService.getClientProfile(this.domain).subscribe((response: any) => {
      if (response) {
        this.customization = response;
        this.contractTypes = response.contractTypes;
        this.setInformationList(this.contractTypes, 'values', 'label', this.translate);

        this.hierarchicalLevels = response.hierarchicalLevels;
        this.setInformationList(this.hierarchicalLevels, 'values', 'label', this.translate);

        // set entities
        this.entities = response.entities;
        this.setInformationList(this.entities, 'entityNames', 'label', this.translate);
      }
    });

    this.translate.onDefaultLangChange.subscribe((val) => {
      this.language = this.translate.getDefaultLang();
    });
  }

  buildThisMonth() {
    this.thisMonth = true;
    let dayCount = 1;
    for (let i = 1; i <= this.lastDayPreviousMonth.getDay(); i++) {
      this.days[dayCount] = {
        selected: false, disabled: true,
        number: this.lastDayPreviousMonth.getDate() - this.lastDayPreviousMonth.getDay() + i,
        month: this.lastDayPreviousMonth.getMonth(), year: this.lastDayPreviousMonth.getFullYear()
      };

      dayCount++;
    }
    let numberOfDaysInAdvance = this.interviewer.calendarSynchronizationConfiguration.maxInterviewOffset;
    for (let i = 1; i <= this.lastDayCurrentMonth.getDate(); i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayCurrentMonth.getFullYear(), this.lastDayCurrentMonth.getMonth(), i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && numberOfDaysInAdvance > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            numberOfDaysInAdvance--;
            isInRange = true;
          }
        }
      }
      this.daysAvailableNextMonth = numberOfDaysInAdvance;
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayCurrentMonth.getMonth(), year: this.firstDayCurrentMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 1; dayCount <= 35; i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayCurrentMonth.getFullYear(), this.lastDayCurrentMonth.getMonth() + 1, i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && numberOfDaysInAdvance > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            numberOfDaysInAdvance--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayCurrentMonth.getMonth() + 1, year: this.firstDayCurrentMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 0; i <= 35; i++) {
      if (this.selectedDay(this.days.find(element => {
        return element && !element.disabled && element.number === this.now.getDate() + i;
      }))) {
        return;
      }
    }
  }

  buildNextMonth() {
    this.thisMonth = false;

    let dayCount = 1;
    let numberOfDaysInAdvance = this.interviewer.calendarSynchronizationConfiguration.maxInterviewOffset;
    for (let i = 1; i <= this.lastDayCurrentMonth.getDay(); i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayCurrentMonth.getFullYear(), this.lastDayCurrentMonth.getMonth(), this.lastDayCurrentMonth.getDate() - this.lastDayCurrentMonth.getDay() + i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && numberOfDaysInAdvance > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            numberOfDaysInAdvance--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            numberOfDaysInAdvance--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: this.lastDayCurrentMonth.getDate() - this.lastDayCurrentMonth.getDay() + i,
        day,
        month: this.lastDayCurrentMonth.getMonth(), year: this.lastDayCurrentMonth.getFullYear(),
        date
      };
      dayCount++;
    }
    // for (let i = 1; i <= this.lastDayCurrentMonth.getDay(); i++) {
    //   this.days[dayCount] = {
    //     selected: false, disabled: true,
    //     number: this.lastDayCurrentMonth.getDate() - this.lastDayCurrentMonth.getDay() + i,
    //     month: this.lastDayCurrentMonth.getMonth(), year: this.lastDayCurrentMonth.getFullYear()
    //   };
    //
    //   dayCount++;
    // }
    for (let i = 1; i <= this.lastDayNextMonth.getDate(); i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayNextMonth.getFullYear(), this.lastDayNextMonth.getMonth(), i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && this.daysAvailableNextMonth > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayNextMonth.getMonth(), year: this.firstDayNextMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 1; dayCount <= 35; i++) {
      let isInRange = false;
      let day: string;
      const date = new Date(this.lastDayNextMonth.getFullYear(), this.lastDayNextMonth.getMonth() + 1, i);
      const isFutureDate = date >= this.now;
      if (isFutureDate && this.daysAvailableNextMonth > 0) {
        if (this.interviewer.calendarSynchronizationConfiguration) {
          if (date.getDay() === 1 && this.interviewer.calendarSynchronizationConfiguration.monday
            && this.interviewer.calendarSynchronizationConfiguration.monday.enable) {
            day = 'monday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 2 && this.interviewer.calendarSynchronizationConfiguration.tuesday
            && this.interviewer.calendarSynchronizationConfiguration.tuesday.enable) {
            day = 'tuesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 3 && this.interviewer.calendarSynchronizationConfiguration.wednesday
            && this.interviewer.calendarSynchronizationConfiguration.wednesday.enable) {
            day = 'wednesday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 4 && this.interviewer.calendarSynchronizationConfiguration.thursday
            && this.interviewer.calendarSynchronizationConfiguration.thursday.enable) {
            day = 'thursday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 5 && this.interviewer.calendarSynchronizationConfiguration.friday
            && this.interviewer.calendarSynchronizationConfiguration.friday.enable) {
            day = 'friday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 6 && this.interviewer.calendarSynchronizationConfiguration.saturday
            && this.interviewer.calendarSynchronizationConfiguration.saturday.enable) {
            day = 'saturday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          } else if (date.getDay() === 0 && this.interviewer.calendarSynchronizationConfiguration.sunday
            && this.interviewer.calendarSynchronizationConfiguration.sunday.enable) {
            day = 'sunday';
            this.daysAvailableNextMonth--;
            isInRange = true;
          }
        }
      }
      this.days[dayCount] = {
        selected: false, disabled: !isFutureDate || !isInRange,
        number: i, day, month: this.firstDayNextMonth.getMonth() + 1, year: this.firstDayNextMonth.getFullYear(), date
      };
      dayCount++;
    }
    for (let i = 0; i <= 35; i++) {
      if (this.selectedDay(this.days.find(element => {
        return element && !element.disabled && element.number === this.now.getDate() + i;
      }))) {
        return;
      }
    }
  }

  changeTime() {
    this.contactInterview.status  = 'PENDING';
  }

  cancel() {
    this.updating = true;
    const event = {
      startDate: this.contactInterview.startDate,
      endDate: this.contactInterview.endDate,
      candidateId: this.candidateId,
      jobId: this.jobId,
      status: 'CANCEL'
    };
    this.candidateService.createEvent(this.domain, this.candidateId, this.contactInterviewId, this.passCode, event).subscribe(
      (result: Interview) => {
        this.updating = false;
        this.contactInterview.status = 'CANCEL';
        this.interview = result;
      },
      (error) => {
        console.error(error);
        this.updating = false;
      });
  }

  valideDate(): boolean {
    return new Date(this.contactInterview.startDate) > this.now;
  }

  validateForm() {
    this.error = {};
    if(!this.contact.firstname) {
      this.error.firstname = "exception.required"
    }
    if(!this.contact.lastname) {
      this.error.lastname = "exception.required"
    }
    if(!this.contact.email) {
      this.error.email = "exception.required"
    }
    if(!this.contact.phoneNumber && this.contactInterview.method ===  'PHONE_INTERVIEW') {
      this.error.phoneNumber = "exception.required"
    }
    if(!this.contact.company && this.interviewer.calendarSynchronizationConfiguration.sharedCalendar.company.required) {
      this.error.company = "exception.required"
    }
    if(!this.contact.reason && this.interviewer.calendarSynchronizationConfiguration.sharedCalendar.reason.required) {
      this.error.reason = "exception.required"
    }
    return this.error.firstname == undefined 
              && this.error.lastname === undefined
              && this.error.email == undefined
              && this.error.phoneNumber == undefined
              && this.error.company == undefined
              && this.error.reason == undefined;
  }

  next(time: Date) {
    this.loadingInterviewSlot = true;
    this.interviews = null;
    this.slotNotAvailable = false;
    let day = this.days.find(element => {
      return element && element.selected;
    })

    this.userService.getCalendar(this.domain, this.interviewerId, day.date).subscribe(
      (userCalendarEvents: CalendarEvent[]) => {
      this.loadingInterviewSlot = false;
      this.updating = false;
      this.convertToDate(day, userCalendarEvents);
      if (this.availableDateTime(time, userCalendarEvents)) {
        this.selectedSlot = true;
        this.contactInterview.startDate  = time;
        this.contactInterview.endDate  = new Date(time.getTime() + this.interviewLength * 60000);
      } else {
        this.selectedDay(day)
        this.updating = false;
        this.slotNotAvailable = true;
      }
    },
      (error) => {
        console.error(error);
        this.selectedDay(day)
        this.updating = false;
        this.slotNotAvailable = true;
      }
    );

  }

  confirm() {

    if(this.validateForm()) {
      this.updating = true;
      this.slotNotAvailable = false;
      let day = this.days.find(element => {
        return element && element.selected;
      })

      this.userService.getCalendar(this.domain, this.interviewerId, day.date).subscribe(
        (userCalendarEvents: CalendarEvent[]) => {
        this.convertToDate(day, userCalendarEvents);
        if (this.availableDateTime( this.contactInterview.startDate, userCalendarEvents)) {
          let contact : Contact = {
            firstName:  this.contact.firstname,
            lastName:  this.contact.lastname,
            email: this.contact.email,
            phoneNumber: this.contact.phoneNumber
          };
          this.contactService.createContact(this.domain, contact).subscribe(
            (result: Contact) => {
              this.contact.id = result.id;
              this.contactService.createEvent(this.domain, result.id, this.contactInterview).subscribe(
                (result: ContactInterview) => {

                  this.justSaved = true;
                  this.updating = false;
                  this.contactInterview.visioConferenceURL  = result.visioConferenceURL;
                  // this.interview = result;
                },
                (error) => {
                  console.error(error);
                  this.updating = false;
                  this.errorInterviewSlot = true;
                });
            },
            (error) => {
              console.error(error);
              this.updating = false;
              this.errorInterviewSlot = true;
            });
        } else {
          this.selectedDay(day)
          this.updating = false;
          this.slotNotAvailable = true;
        }
      },
        (error) => {
          console.error(error);
          this.selectedDay(day)
          this.updating = false;
          this.slotNotAvailable = true;
        }
      );
        
    }

  }


  setInterviewLength(length: number) {
    this.interviewLength = length;
    this.buildThisMonth();
  }

  selectedDay(day: Day) {
    this.days.map(element => element.selected = false);
    this.interviews = undefined;
    this.interview = undefined;
    if (day && !day.disabled) {
      this.loadingInterviewSlot = true;
      this.days.map(element => element.selected = false);
      day.selected = true;
      this.interview = {date : day.date};
      this.interviews = undefined;
      this.errorInterviewSlot = false;
      this.userService.getCalendar(this.domain, this.interviewerId, day.date).subscribe(
        (userCalendarEvents: CalendarEvent[]) => {
        this.convertToDate(day, userCalendarEvents);
        this.loadingInterviewSlot = false;

        this.interviews = [];
        const dayConfig = this.interviewer.calendarSynchronizationConfiguration[day.day];
        const startHours  = dayConfig.startTimeOfDay.split(':')[0];
        const startMinutes  = dayConfig.startTimeOfDay.split(':')[1];
        const startMoment  = moment.tz(new Date(day.year, day.month,  day.number, startHours, startMinutes), this.interviewer.timezone);
        startMoment.hours(startHours);
        let startDate = startMoment.toDate();
        if (startDate <  this.now) {
          startDate =  new Date(this.now.getTime() + this.interviewer.calendarSynchronizationConfiguration.minInterviewLeadTime * 60000);
          if (startDate.getMinutes() > 0 && startDate.getMinutes() <= 14) {
            startDate.setMinutes(15);
          } else if (startDate.getMinutes() > 15 && startDate.getMinutes() <= 29) {
            startDate.setMinutes(30);
          } else if (startDate.getMinutes() > 30 && startDate.getMinutes() <= 44) {
            startDate.setMinutes(45);
          } else {
            startDate.setHours(startDate.getHours() + 1);
            startDate.setMinutes(0);
          }
        }
        const endHours = dayConfig.endTimeOfDay.split(':')[0];
        const endMinutes  = dayConfig.endTimeOfDay.split(':')[1];
        const endMoment  = moment.tz(new Date(day.year, day.month,  day.number, endHours, endMinutes), this.interviewer.timezone);
        endMoment.hours(endHours);
        const endDate = endMoment.toDate();
        if (endDate < startDate) {
          return;
        }
        do {
          if (this.availableDateTime(startDate, userCalendarEvents)) {
            this.interviews.push({date: startDate, selected: false});
          }
          startDate =  new Date(startDate.getTime() + 15 * 60000);
        } while (startDate.getTime() + this.interviewLength * 60000 <= endDate.getTime());

      },
        (error) => {
          console.error(error);
          this.errorInterviewSlot = true;
          this.loadingInterviewSlot = false;
        }
      );
      return true;
    } else {
      return false;
    }
  }

  private availableDateTime(date: Date, events: CalendarEvent[]) {
    for (const event of events) {
      if (date >= event.startDateTime && date  < event.endDateTime) {
        return false;
      }
      const startDate = new Date(date.getTime() + this.interviewLength * 60000);
      if (startDate > event.startDateTime && startDate < event.endDateTime) {
        return false;
      }
    }
    return true;
  }

  private convertToDate(day: Day, calendarEvents: CalendarEvent[]) {
    for (const event of calendarEvents) {
      let startYear;
      let startMonth;
      let startDay;
      if(event.startDate !== "") {
        startYear  = Number(event.startDate.split('-')[0]);
        startMonth  = Number(event.startDate.split('-')[1]) - 1;
        startDay  = Number(event.startDate.split('-')[2]);
      } else {
        startYear  = day.year;
        startMonth  = day.month;
        startDay  = day.number;
      }
      const startHours  = Number(event.startTime.split(':')[0]);
      const startMinutes  =  Number(event.startTime.split(':')[1]);
      const timeZone = event.timeZone;
      const startMoment  = moment.tz(new Date(startYear, startMonth,  startDay, startHours, startMinutes), timeZone);
      startMoment.hours(Number(startHours));
      event.startDateTime = startMoment.toDate();
      const endHours  = Number(event.endTime.split(':')[0]);
      const endMinutes  =  Number(event.endTime.split(':')[1]);
      let endYear;
      let endMonth;
      let endDay;
      if(event.endDate !== "") {
        endYear  = Number(event.endDate.split('-')[0]);
        endMonth  = Number(event.endDate.split('-')[1]) -1;
        endDay  = Number(event.endDate.split('-')[2]);
      } else {
        endYear  = day.year;
        endMonth  = day.month;
        endDay  = day.number;
      }
      const endMoment  = moment.tz(new Date(endYear, endMonth,  endDay, endHours, endMinutes), timeZone);
      endMoment.hours(endHours);
      event.endDateTime = endMoment.toDate();
    }
  }

  selectedTime(interview) {
    this.interviews.map(element => element.selected = false);
    interview.selected = true;
  }

}

export interface ContactError {
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  company?: string;
  reason?: string;
}

export interface ContactView {
  id?: string
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  company?: string;
  reason?: string;
}

export interface Day {
  selected: boolean;
  disabled: boolean;
  number: number;
  day?: string;
  month: number;
  year: number;
  date?: Date;
}
